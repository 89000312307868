<template>
  <v-overlay :model-value="overlay" opacity="0.2" style="height: 100vh; weight: 100%; display: flex; justify-content: center; align-items: center">
    <v-img max-width="150px" src="@/assets/loading-animation.gif"></v-img>
    <div class="text-secondary text-center">Please wait...</div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
