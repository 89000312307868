<template>
  <div class="card mb-1  mt-2">
    <v-card color="transparent" elevation="0" height="auto" class="" v-if="StoreObj && StoreObj.ticket_tags">
      <v-row no-gutters v-if="groupByTagType && groupByTagType.MASTER_TAG">
        <v-col class="" cols="12">Tags</v-col>
        <v-chip variant="flat" size="small" v-for="(tag, id) in groupByTagType.MASTER_TAG" :key="id" :color="tag.tag_colour" small class="mb-2 mr-2 font-weight-bold">{{
          tag.tag_label
        }}</v-chip>
      </v-row>
      <v-row no-gutters v-if="groupByTagType && groupByTagType.SUB_TAG && groupByTagType.SUB_TAG.length > 0">
        <v-col class="" cols="12">Sub Tag</v-col>
        <v-chip variant="flat" size="small" v-for="(tag, id) in groupByTagType.SUB_TAG" :key="id" :color="tag.tag_colour" small class="mb-2 mr-2 font-weight-bold">{{
          tag.tag_label
        }}</v-chip>
      </v-row>
      <v-row no-gutters v-if="groupByTagType && groupByTagType.SUB_SUB_TAG && groupByTagType.SUB_SUB_TAG.length > 0">
        <v-col class="" cols="12">Sub Sub Tag</v-col>
        <v-chip variant="flat" size="small" v-for="(tag, id) in groupByTagType.SUB_SUB_TAG" :key="id" :color="tag.tag_colour"  class="mb-2 mr-2 font-weight-bold">{{
          tag.tag_label
        }}</v-chip>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    StoreObj: Object,
  },

  mounted() {},
  data: () => ({}),
  computed: {
    groupByTagType() {
      if (this.StoreObj.ticket_tags && this.StoreObj.ticket_tags.length > 0) {
        return this.StoreObj.ticket_tags.reduce((groups, object) => {
          const tagType = object.tag_type;
          if (!groups[tagType]) {
            groups[tagType] = [];
          }
          groups[tagType].push(object);
          return groups;
        }, {});
      }
    },
  },
  methods: {
    tagCardEmit(toggle) {
      this.$emit("clicked", toggle);
    },
  },
};
</script>
<style scoped>
.tag-title {
  font-weight: normal;
  color: black;
  padding-left: 8px;
}
.open-tags-link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
</style>
