import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "../views/HomeView.vue";
import FeedbackFrom from "../views/FeedbackFrom.vue";

const routes = [
  {
    path: "/web-form/:ticketId/:stageId",
    name: "home",
    component: HomeView,
  },
  {
    path: "/feedback/:ticketid",
    name: "feddback",
    component: FeedbackFrom,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
