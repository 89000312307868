<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fontSizeSmall {
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #343f52 !important;
}
</style>
