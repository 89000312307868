<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog :model-value="dialogcloseTicketWarning" persistent max-width="400px">
      <v-card>
        <v-toolbar dark density="compact" class="elevation-0" color="primary">
          <div class="ml-2">Close Task</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogcloseTicketWarningEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" color="red">mdi-check</v-icon>
          <div>Are you sure you want to close the Task ?</div>
          <v-form ref="form" v-if="isExternal">
            <v-text-field
              :rules="[(v) => !!v || 'Email ID is required', (v) => /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(v) || 'Invalid email address']"
              v-model.trim="lowercaseEmail"
              label="Enter Your Email Id *"
              variant="outlined"
              density="compact"
              class="mt-3">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" variant="outlined" @click="dialogcloseTicketWarningEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn variant="flat" class="borderRadius text-capitalize mr-2" color="primary" @click="checkItem" :disabled="disabled"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "./Snackbar.vue";
export default {
  props: {
    dialogcloseTicketWarning: Boolean,
    action: String,
    isExternal: Boolean,
  },
  components: { Snackbar },

  data: () => ({
    Toggle: 0,
    user_email_id: "",
    SnackBarComponent: {},
    disabled: true,
  }),
  computed: {
    lowercaseEmail: {
      get() {
        return this.user_email_id.toLowerCase();
      },
      set(value) {
        this.user_email_id = value;
      },
    },
  },
  watch: {
    dialogcloseTicketWarning(val) {
      if (val) this.disabled = true;
    },
    user_email_id(val) {
      if (/^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(val)) {
        this.disabled = false;
      }
    },
  },

  methods: {
    checkItem() {
      if (this.$refs.form.validate()) {
        this.dialogcloseTicketWarningEmit(2, this.user_email_id);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with(*) are mandatory",
        };
      }
    },
    dialogcloseTicketWarningEmit(Toggle, user_email_id) {
      this.user_email_id = "";
      this.$emit("clicked", Toggle, user_email_id);
    },
  },
};
</script>
