<template>
  <v-expansion-panels accordion multiple v-model="panels">
    <v-expansion-panel class="panel1 mb-1 pa-0 rounded-lg">
      <v-expansion-panel-title>
        <h3>Ticket Information</h3>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card elevation="0" class="pa-0" color="#fafafa">
          <v-row no-gutters>
            <v-col cols="12" class="text-greyText">Ticket No </v-col>

            <v-col cols="12" class="text-primary font-weight-bold mb-2">{{ StoreObj.ticket_display_id }}</v-col>
            <v-col cols="12" xs="12" sm="12" class="text-greyText" v-if="StoreObj.ticket_owner_name">Ticket Owner </v-col>

            <v-col v-if="StoreObj.ticket_owner_name" cols="6" xs="12" sm="12" class="font-weight-bold mb-2">
              <span
                ><v-avatar class="avatar" size="30">{{ StoreObj.ticket_owner_name.substring(0, 2).toUpperCase() }}</v-avatar></span
              >{{ StoreObj.ticket_owner_name }}</v-col
            >
            <v-col cols="12" xs="12" sm="12" class="text-greyText">Type </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="font-weight-bold mb-2">
              <v-icon left small color="secondary" v-if="StoreObj.ticket_origin != 'MANUAL'">{{
                StoreObj.ticket_origin == "EMAIL"
                  ? "mdi-email"
                  : StoreObj.ticket_origin == "OUTBOUNDCALL"
                  ? "mdi-phone-outgoing"
                  : "mdi-phone-incoming"
              }}</v-icon
              >{{ StoreObj.ticket_origin }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" class="text-greyText" v-if="StoreObj.ticket_origin != 'MANUAL' && StoreObj.ticket_description"
              >From
            </v-col>
            <v-col cols="12" xs="12" sm="12" class="font-weight-bold mb-2" v-if="StoreObj.ticket_origin != 'MANUAL' && StoreObj.ticket_description"
              >{{
                StoreObj.ticket_origin == "EMAIL" && StoreObj.ticket_description
                  ? StoreObj.ticket_description["email"][0]["from"]
                  : StoreObj.ticket_description.From
              }}
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              class="text-greyText"
              v-if="StoreObj.ticket_origin == 'OUTBOUNDCALL' || StoreObj.ticket_origin == 'INBOUNDCALL'"
              >To
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              class="font-weight-bold mb-2"
              v-if="StoreObj.ticket_origin == 'OUTBOUNDCALL' || (StoreObj.ticket_origin == 'INBOUNDCALL' && StoreObj.ticket_description)"
              >{{ StoreObj.ticket_description.To }}
            </v-col>
            <v-col
              cols="12"
              class="text-greyText"
              v-if="
                this.StoreObj.ticket_origin != 'MANUAL' &&
                this.StoreObj.ticket_origin != 'OUTBOUNDCALL' &&
                this.StoreObj.ticket_origin != 'INBOUNDCALL'
              "
              >Subject:
            </v-col>
            <v-col
              cols="12"
              class="font-weight-bold"
              v-if="
                this.StoreObj.ticket_origin != 'MANUAL' &&
                this.StoreObj.ticket_origin != 'OUTBOUNDCALL' &&
                this.StoreObj.ticket_origin != 'INBOUNDCALL' &&
                StoreObj.ticket_description
              "
              >{{ StoreObj.ticket_description["email"][0]["subject"] }}
            </v-col>

            <v-col cols="12" class="mt-2">Status </v-col>
            <v-col cols="12" class="font-weight-bold">
              <span class="text-center">{{ getColor(StoreObj.status).text }}</span>
            </v-col>
            <v-col cols="12">
              <TicketTags :StoreObj="StoreObj" />
            </v-col>
            <v-col cols="12" class="" v-if="StoreObj.ticket_form_details">
              <v-row no-gutters v-for="(field, index) in StoreObj.ticket_form_details" :key="index">
                <v-col cols="12" class="mt-2">{{ field.field_name.replace(/_/g, " ") }} </v-col>
                <v-col cols="12" class="font-weight-bold">
                  <span class="text-center">{{ field.field_value }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel class="panel1 rounded-lg">
      <v-expansion-panel-title>
        <h3>Lead Information</h3>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-card elevation="0" class="mb-2 panel1">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12">
              <v-row class="mb-2" no-gutters v-for="(lead, i) in lead_details" :key="i">
                <v-col cols="12" class="text-greyText text-capitalize"
                  >{{ lead.field_name == "timestamp" ? "Created On" : lead.field_name.replace(/_/g, " ") }}
                </v-col>
                <v-col cols="12" class="font-weight-bold">
                  {{ lead.field_name == "timestamp" ? new Date(Number(lead.value)).toLocaleString("en-IN", { hour12: true }) : lead.value }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card></v-expansion-panel-text
      ></v-expansion-panel
    >
  </v-expansion-panels>
</template>
<script>
import TicketTags from "@/components/Tickets/TicketTags.vue";
import { GetColors } from "@/mixins/GetColors.js";

export default {
  props: {
    StoreObj: Object,
  },
  components: {
    TicketTags,
  },
  mixins: [GetColors],
  data: () => ({ lead_details: [], panels: [0] }),
  watch: {
    StoreObj(val) {
      if (val) {
        if (val.lead_details && val.lead_details.form_id) this.getLeadDeatils(val.lead_details);
      }
    },
  },
  mounted() {
    if (this.StoreObj.lead_details && this.StoreObj.lead_details.form_id) this.getLeadDeatils(this.StoreObj.lead_details);
  },
  methods: {
    ticketInformationCardEmit(toggle, StoreObj, number) {
      this.$emit("clicked", toggle, StoreObj, number);
    },
    async getLeadDeatils(leadObj) {
      if (leadObj && leadObj !== null) {
        this.lead_details = [];

        Object.entries(leadObj).forEach(([key, value]) => {
          if (!key.includes("_id")) {
            this.lead_details.push({
              field_name: key,
              value: value,
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.v-expansion-panels .panel1 {
  background: #fafafa;
}
.avatar {
  border: 1px solid grey;
  margin-right: 5px;
  background: white;
  font-weight: normal;
}
</style>
