import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import { createVuetify } from "vuetify";

export default createVuetify(
  {
    theme: {
      themes: {
        light: {
          colors: {
            primary: "#f47f40", // #E53935
            secondary: "#343f52", // #FFCDD2

            redChipColor: "#f58488",
            greenChipColor: "#99f0b3",
          },
        },
      },
    },
  }
);
