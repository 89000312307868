<template>
  <v-card elevation="0" variant="outlined" color="transparent" :height="OuterCardHeight - 25" class="overflow-y-auto px-0 py-0">

    <v-expansion-panels class="rounded-lg" multiple accordion v-model="panels">
      <v-expansion-panel class="panel1 mb-1 rounded-lg">
        <v-expansion-panel-title>
          <h3>Ticket Logs</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-if="StoreObj && StoreObj.ticket_logs">
            <v-card color="" variant="flat" class="mb-2 pa-1">
              <div v-for="(item, index) in StoreObj.ticket_logs.slice().reverse()" :key="index">
                {{item.type}}
                <div v-if="!item.type">
                  {{item.action}}
                  <div v-if="item.action === 'COMMENTED/ATTACHED DOCUMENT ABOUT TICKET'">
                    <div>
                      {{ item.commented_by_user_email }} commented on
                      {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                    </div>
                    <div v-if="item.comments"><span class="font-weight-bold">Comment: </span>{{ item.comments }}</div>
                    <div class="py-2 font-weight-bold" v-if="item.attachments && item.attachments.length > 0">Attachments:</div>
                    <div>
                      <v-row v-if="item.attachments && item.attachments.length > 0">
                        <v-col
                          cols="3"
                          xs="12"
                          sm="6"
                          md="3"
                          align="center"
                          justify="center"
                          v-for="(commentAttchment, idx) in item.attachments"
                          :key="idx">
                          <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                              <v-card
                                 v-bind="props"
                                
                                tile
                                variant="outlined"
                                width="50px"
                                height="50px"
                                class="my-2 fileCard"
                                @click="open(commentAttchment.url)">
                                <v-icon size="20" color="purple" class="mt-3">
                                  {{ getIcon(commentAttchment.filename) }}
                                </v-icon>
                              </v-card>
                            </template>
                            <span>{{ commentAttchment.filename }} </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div v-else>
                    {{
                      `${item.comments}  on ${new Date(item.log_time).toLocaleString("en-IN", {
                        hour12: true,
                      })}`
                    }}
                    <v-row no-gutters v-if="item.action == 'CALLED LEAD/CUSTOMER'">
                      <v-col cols="2">
                        <v-icon
                          v-if="item.call_recording && item.call_recording != 'UNAVAILABLE'"
                          class="mt-1"
                          large
                          color="blue"
                          @click="openCallRecording(item)"
                          >mdi-record-rec</v-icon
                        >
                      </v-col>
                    </v-row>
                  </div>

                  <v-divider class="my-2"></v-divider>
                </div>
              </div>

              
            </v-card>
            <v-card v-if="StoreObj.workqueue_data" color="transparent" variant="outlined" class="pa-1 overflow-x-hidden">
              <div v-for="(stage, i) in StoreObj.workqueue_data.workqueue_stages" :key="i">
                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" align="center" justify="center" class="">
                    <strong class="" v-if="StoreObj.ticket_logs && StoreObj.ticket_logs[stage.stage_name]">
                      {{ stage.stage_name.replace(/_/g, " ") }}
                    </strong>
                  </v-col>
                  <v-col
                    align="center"
                    cols="12"
                    v-if="StoreObj.ticket_logs && StoreObj.ticket_logs[stage.stage_name] && StoreObj.ticket_logs[stage.stage_name].length == 0">
                    <div>No action performed in this Ticket</div>
                  </v-col>
                </v-row>

                <div
                  class="mt-2"
                  v-if="StoreObj.ticket_logs && StoreObj.ticket_logs[stage.stage_name]"
                  v-for="(item, idx) in StoreObj.ticket_logs[stage.stage_name]"
                  :key="idx">
                  <div v-if="item.action == 'CALLED LEAD/CUSTOMER'">
                    <v-row no-gutters>
                      <v-col cols="2" align="center" justify="center">
                        <v-icon class="mt-3" :color="item.status == 'completed' ? 'green' : 'red'"> mdi-phone-outgoing </v-icon>
                      </v-col>
                      <v-col cols="8">
                        <v-row no-gutters>
                          <v-col class="text-left" cols="12"><span class="font-weight-bold"> Called By: </span>{{ item.called_by }} </v-col>
                          <v-col class="text-left" cols="12">
                            <span class="font-weight-bold"> Called On: </span> {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2">
                        <v-icon
                          v-if="item.call_recording && item.call_recording != 'UNAVAILABLE'"
                          class="mt-1"
                          large
                          color="blue"
                          @click="openCallRecording(item)"
                          >mdi-record-rec</v-icon
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <div v-if="item.action == 'COMMENTED/ATTACHED DOCUMENT ABOUT TICKET'">
                    <div>
                      {{ item.commented_by_user_email }} commented on
                      {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                    </div>
                    <div v-if="item.comment"><span class="font-weight-bold">Comment: </span>{{ item.comment }}</div>
                  </div>
                  <div
                    v-if="item.action == 'COMPLETED STAGE' || item.action == 'COMPLETED STAGE and CLOSED TICKET' || item.action === 'CLOSED TICKET'">
                    <div>
                      Stage completed by {{ item.completed_by_user_email }} on
                      {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                    </div>
                  </div>
                  <div v-if="item.action == 'REJECTED STAGE'">
                    <div>
                      Ticket rejected by {{ item.completed_by_user_email }} on
                      {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                    </div>
                  </div>

                  <div class="py-2 font-weight-bold" v-if="item.attachments && item.attachments.length > 0">Attachments:</div>
                  <div>
                    <v-row v-if="item.attachments && item.attachments.length > 0">
                      <v-col
                        cols="3"
                        xs="12"
                        sm="6"
                        md="3"
                        align="center"
                        justify="center"
                        v-for="(commentAttchment, idx) in item.attachments"
                        :key="idx">
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props }">
                            <v-card
                               v-bind="props"
                              
                              tile
                              variant="outlined"
                              width="50px"
                              height="50px"
                              class="my-2 fileCard"
                              @click="open(commentAttchment.url)">
                              <v-icon size="20" color="purple" class="mt-3">
                                {{ getIcon(commentAttchment.filename) }}
                              </v-icon>
                            </v-card>
                          </template>
                          <span>{{ commentAttchment.filename }} </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider />
                </div>
              </div>
            </v-card>
            <v-card color="transparent" variant="outlined" class="pa-1 mt-2">
              <div v-for="item in StoreObj.ticket_logs.final">
                <div v-if="item.action == 'CLOSED TICKET'">
                  <div>
                    Ticket closed by {{ item.assigned_by_user_email_id }} on
                    {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                  </div>
                </div>
              </div>
            </v-card>
            <v-card v-if="StoreObj.ticket_feedback_values" color="transparent" variant="outlined" class="pa-1 mt-2">
              <div>
                <h3>Customer Feedback</h3>
                <div class="font-weight-bold">
                  {{ StoreObj.ticket_feedback_values.customer_happy
                  }}<v-icon class="mt-n1" :color="getRatingColor(StoreObj.ticket_feedback_values.customer_happy)" small>mdi-star</v-icon>
                </div>
              </div>
            </v-card>
          </div>
          <div v-else>
            <h4 class="text-grey text-center">No ticket logs found.</h4>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <!-- Call Logs of the Ticket -->
      <v-expansion-panel class="panel2 mb-1 rounded-lg">
        <v-expansion-panel-title>
          <h3>Call Logs</h3>
        </v-expansion-panel-title>
       
        <v-expansion-panel-text>
          <v-card v-if="StoreObj && StoreObj.ticket_logs" elevation="0" class="text-left" rounded="25" color="transparent">
            <v-row no-gutters v-for="(item, idx) in StoreObj.ticket_logs.slice().reverse()" :key="idx">
              <div v-if="item.type == 'CALL-LOG'">
                <v-row no-gutters>
                  <v-col cols="2" align="center" justify="center">
                    <v-icon class="mt-3" :color="StoreObj.ticket_description && StoreObj.ticket_description.Status == 'completed' ? 'green' : 'red'">
                      {{
                        StoreObj.ticket_description && StoreObj.ticket_description.Direction == "incoming"
                          ? "mdi-phone-incoming"
                          : "mdi-phone-outgoing"
                      }}
                    </v-icon>
                  </v-col>
                  <v-col cols="8">
                    <v-row no-gutters>
                      <v-col class="text-left" cols="12"><span class="font-weight-bold"> Called By: </span>{{ item.called_by }} </v-col>
                      <v-col class="text-left fontSizeSmall" cols="12">
                        <!-- <span class="font-weight-bold"> Called On: </span> -->
                        {{ new Date(item.log_time).toLocaleString("en-IN", { hour12: true }) }}
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2">
                  </v-col>
                  <v-divider class="my-2"></v-divider>
                </v-row>
              </div>
            </v-row>
          </v-card>

          <div v-else>
            <h4 class="text-grey text-center">No call logs found.</h4>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { GetColors } from "@/mixins/GetColors";
export default {
  props: {
    StoreObj: Object,
    GetAllWorkQueuesStagesList: Array,
  },
  mixins: [
    GetColors,
  ],
  data: () => ({
    height: 0,
    panels: [0, 1],
    OuterCardHeight: 0,
    callLogObject: {},
    dialogViewCallRecording: false,
  }),
  async mounted() {
    this.OuterCardHeight = window.innerHeight - 180;
    this.height = window.innerHeight - 325;

    
  },
  methods: {
    timeAgo(timestamp) {
      const now = new Date();
      const seconds = Math.floor((now - timestamp) / 1000);

      let interval = Math.floor(seconds / 31536000);

      if (interval > 1) {
        return interval + " years ago";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months ago";
      }
      interval = Math.floor(seconds / 604800);
      if (interval > 1) {
        return interval + " weeks ago";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " days ago";
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " hours ago";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    },
    open(url) {
      window.open(url);
    },
    openTicket(item) {
      this.$router.push({
        name: "ticket-details",
        params: { id: item.ticket_id },
        query: { item: item },
      });
    },
    openCallRecording(callLog) {
      this.callLogObject = callLog;
      this.dialogViewCallRecording = true;
    },
    dialogViewCallRecordingEmit(Toggle) {
      this.dialogViewCallRecording = false;
    },
    getRatingColor(rating) {
      const ratingObjects = ["red", "orange", "purple", "indigo", "green"];

      return ratingObjects[rating - 1];
    },
  },
};
</script>
<style scoped>
.v-card .v-card__text {
  padding: 0px;
}
.v-expansion-panels .panel1 {
  /* background: rgb(244, 240, 219); */
  background: #fafafa;
}
.v-expansion-panels .panel2 {
  /* background: #eaf9ef; */
  background: #fafafa;
}
</style>
