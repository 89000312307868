<template>
  <div v-if="StoreObj">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <ReplyToEmailThreadDialog :dialogReplytToEmail="dialogReplytToEmail" :StoreObj="StoreObj" @clicked="dialogReplytToEmailEmit" />
    <v-card elevation="1" outlined color="white" class="pa-0 overflow-y-auto overflow-x-hidden rounded-lg custom-scrollbar" :height="OuterCardHeight">
      <v-card-text class="pa-2" style="position: sticky; top: 0; z-index: 1; background-color: white">
        <div class="d-flex justify-space-between">
          <div class="text-black d-flex justify-space-between">
            <v-img v-if="StoreObj.ticket_origin === 'EMAIL'" src="../../assets/email.png" height="25px" width="25px" />
            <v-img
              v-if="StoreObj.ticket_origin === 'INBOUNDCALL' || StoreObj.ticket_origin === 'OUTBOUNDCALL'"
              src="../../assets/callIcon.png"
              height="25px"
              width="25px" />
            <v-img v-if="StoreObj.ticket_origin === 'MANUAL'" src="../../assets/manualTicket.png" height="25px" width="25px" />

            <h3 class="pl-2 d-flex justify-space-between" v-if="StoreObj.ticket_description">
              {{ StoreObj.ticket_description.subject ? StoreObj.ticket_description.subject : "No subject" }}
            </h3>
          </div>
          <div>
            <v-btn
              size="x-small"
              color="primary"
              class="text-capitalize"
              v-if="StoreObj.status !== 'CLOSED' && StoreObj.ticket_origin !== 'EMAIL' && editTicketSubjet"
              small
              @click="addSubjectToTicket">
              save
            </v-btn>
            <!-- <v-btn icon @click="editTicketSubjet = !editTicketSubjet" v-if="StoreObj.status !== 'CLOSED' && StoreObj.ticket_origin !== 'EMAIL'">
              <v-icon color="primary" small> mdi-pencil </v-icon>
            </v-btn> -->
            <v-btn
              v-if="
                isExternalStage &&
                StoreObj.status !== 'CLOSED' &&
                (StoreObj.ticket_origin === 'EMAIL' || StoreObj.ticket_origin === 'MANUAL') &&
                StoreObj.ticket_description.email &&
                StoreObj.ticket_description.email.length > 0
              "
              size="x-small"
              class="text-capitalize"
              dark
              depressed
              color="#0073ff"
              @click="dialogReplytToEmail = true">
              <v-icon left small class="">mdi-reply-all-outline</v-icon> Reply
            </v-btn>
          </div>
        </div>
        <div class="py-1 d-flex justify-start">
          <v-chip size="small" color="secondary"> #{{ StoreObj.ticket_display_id }} </v-chip>
          <v-tooltip location="bottom" color="secondary">
            <template v-slot:activator="{ props }">
              <div class="ml-3" v-bind="props">
                <v-icon color="grey" small class="">mdi-clock-time-two-outline</v-icon>
                {{ new Date(StoreObj.raised_on).toLocaleString() }}
              </div>
            </template>
            <span>Ticket Created on {{ new Date(StoreObj.raised_on).toLocaleString() }} </span>
          </v-tooltip>
        </div>
        <v-tabs small color="secondary" fixed-tabs v-model="tab">
          <v-tab>Conversations</v-tab>
          <v-tab>Attachments</v-tab>
        </v-tabs>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-tabs-window v-model="tab">
          <!-- CONVERSATIONS -->
          <v-tabs-window-item>
            <v-row no-gutters>
              <v-col
                align="center"
                justify="center"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                v-if="this.StoreObj.ticket_origin !== 'EMAIL' && this.StoreObj.ticket_origin != 'MANUAL'">
                <v-card :height="OuterCardHeight - 58">
                  <h2 class="text-grey pt-16"><v-icon left color="grey">mdi-chat-remove-outline</v-icon>No conversations found.</h2>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                v-if="this.StoreObj.ticket_origin == 'EMAIL' || this.StoreObj.ticket_origin == 'MANUAL'">
                <h3 class="pl-2">
                  Email Subject:
                  {{ StoreObj.ticket_origin === "EMAIL" ? StoreObj.ticket_description["email"][0]["subject"] : StoreObj.ticket_description.subject }}
                </h3>
                <!-- <div v-for="(splittedEmial, id) in StoreObj.ticket_description.latestEmailContent.split('----SPLIT_HERE----')" :key="id">
                  <h3 class="pl-2" v-if="id === 0">
                    Email Subject:
                    {{
                      StoreObj.ticket_origin === "EMAIL" ? StoreObj.ticket_description["email"][0]["subject"] : StoreObj.ticket_description.subject
                    }}
                  </h3> -->
                <div v-for="(thread, id) in StoreObj.ticket_description.email" :key="id">
                  <!-- <v-card height="" class="overflow-auto elevation-2 mt-2 pa-2" v-html="removeTagsAndEntities(splittedEmial)"></v-card> -->
                  <v-card class="overflow-auto elevation-2 mt-2 pa-2">
                    <div class="d-flex justify-space-between">
                      <div>
                        <strong>From: </strong>
                        <span
                          ><u>{{ thread.from }}</u>
                        </span>
                      </div>

                      <div class="d-flex justify-space-between">
                        <span style="color: #666">{{ new Date(thread.date).toLocaleString("en-IN", { hour12: true }) }}</span>
                      </div>
                    </div>

                    <div>
                      <strong>To: </strong>
                      <span v-if="thread.toHtml.value.length > 0" v-for="(toAddress, idx) in thread.toHtml.value" :key="idx"
                        ><u>{{ toAddress.address.trim() }} </u>{{ idx !== thread.toHtml.value.length - 1 ? ", " : "" }}
                      </span>
                      <span v-if="thread.toHtml.value.length == 0" v-for="(toAddress, idx) in thread.to" :key="idx"
                        ><u>{{ toAddress }} </u>
                      </span>
                    </div>

                    <div v-if="thread.ccHtml.value && thread.ccHtml.value.length > 0">
                      <strong>CC: </strong>
                      <span v-for="(ccAddress, idx) in thread.ccHtml.value" :key="idx"
                        ><u>{{ ccAddress.address }}</u
                        >{{ idx !== thread.ccHtml.value.length - 1 ? ", " : "" }}
                      </span>
                    </div>
                    <v-card-text class="px-0">
                      <div v-html="removeTagsAndEntities(thread.textMessage)"></div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <!-- ATTACHMENTS -->
          <v-tabs-window-item v-if="StoreObj.ticket_description">
            <v-row no-gutters>
              <v-col align="center" justify="center" cols="12" xs="12" sm="12" md="12" lg="12" v-if="this.StoreObj.ticket_origin !== 'EMAIL'">
                <v-card :height="OuterCardHeight - 58">
                  <h2 class="text-grey pt-16"><v-icon left color="grey">mdi-paperclip-off</v-icon>No Attachments found.</h2>
                </v-card>
              </v-col>
              <v-col v-if="StoreObj.ticket_origin == 'EMAIL'">
                <EmailAttachments :emailAttachments="StoreObj.ticket_description.email" />
              </v-col>
            </v-row>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import ReplyToEmailThreadDialog from "@/components/Tickets/Dialogs/ReplyToEmailThreadDialog.vue";
import EmailAttachments from "./EmailAttachments.vue";
export default {
  props: {
    StoreObj: Object,
    isExternalStage: Boolean,
  },
  components: {
    Snackbar,
    EmailAttachments,
    ReplyToEmailThreadDialog,
  },
  data: () => ({
    ticketSubject: "",
    tab: null,
    panels: [0, 1],
    stageIndex: 0,
    OuterCardHeight: 0,
    editTicketSubjet: false,
    applyBackground: true,
    dialogReplytToEmail: false,
    dialogCreateWorkQueueStage: false,
    rearrangeTicketStagesDaialog: false,
    stageObj: {},
    SnackBarComponent: {},
    items: ["Conversations", "Timeline", "Attachments", "History", "Call Logs"],
    firstPart: [],
    secondPart: [],
  }),
  watch: {
    StoreObj(value) {
      if (value && value.ticket_origin !== "EMAIL") this.ticketSubject = value.ticket_description.subject;
    },
  },
  computed: {
    getCurrentStageIndex() {
      if (this.StoreObj.workqueue_data) {
        let index = 0;
        for (let i = 0; i < this.StoreObj.workqueue_data.workqueue_stages.length; i++) {
          if (this.StoreObj.workqueue_data.workqueue_stages[i].stage_name == this.StoreObj.ticket_stage_name) {
            index = i;
            if (index == 0) {
              this.firstPart = [this.StoreObj.workqueue_data.workqueue_stages[0]];
            } else {
              this.firstPart = this.StoreObj.workqueue_data.workqueue_stages.slice(0, index);
            }
            if (this.StoreObj.workqueue_data.workqueue_stages.length > 2) {
              this.secondPart = this.StoreObj.workqueue_data.workqueue_stages.slice(index + 1);
            }
          }
        }

        return index;
      }
    },
  },
  mounted() {
    if (this.StoreObj && this.StoreObj.ticket_origin !== "EMAIL" && this.StoreObj.ticket_description)
      this.ticketSubject = this.StoreObj.ticket_description.subject;

    this.OuterCardHeight = window.innerHeight - 120;
  },
  methods: {
    removeTagsAndEntities(input) {
      let cleanedString = input.replace(/&lt;/g, "");
      cleanedString = cleanedString.replace(/&gt;/g, "");

      return cleanedString;
    },
    isFirstTimelineItem(index) {
      return index === 0;
    },
    millisecondsToDuration(milliseconds) {
      const totalHours = milliseconds / (60 * 60 * 1000);
      return `${totalHours.toFixed(0)} Hours`;
    },
    getColor(index) {
      if (this.getCurrentStageIndex && this.getCurrentStageIndex === index) {
        return "primary";
      } else if (index < this.getCurrentStageIndex) {
        return "green";
      } else {
        return "grey";
      }
    },

    CreateOrEditStage(action, item, index) {
      if (action == "EDIT") {
        this.stageObj = item;
        this.stageIndex = index;
      }
      this.stageObj.action = action;
      this.dialogCreateWorkQueueStage = true;
    },
    dialogCreateWorkQueueStageEmit(Toggle) {
      this.dialogCreateWorkQueueStage = false;
      if (Toggle == 2) {
        this.TimelineCardEmit(2);
      }
    },
    rearrangeTicketStagesDialogEmit(Toggle) {
      this.rearrangeTicketStagesDaialog = false;
      if (Toggle == 2) {
        this.TimelineCardEmit(2);
      }
    },
    TimelineCardEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
    async dialogReplytToEmailEmit(toggle) {
      this.dialogReplytToEmail = false;
      if (toggle === 2) this.TimelineCardEmit(toggle);
    },
  },
};
</script>
